.h-200 {
    height: 200px;
    object-fit: contain;
}

.loading-icon {
    position: absolute;
    top: -2%;
    left: 50%;
    transform: translate(-2%, -50%);
}

.h-150 {
    height: 150px;
}

.h-300 {
    height: 300px;
}

.object-cover {
    object-fit: cover;
}

.custom-container {
    position: relative;
    background-color: transparent;
    border-radius: 10px;
}

.custom-container::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%; /* Adjust the height of the transparent area */
    background: white;
    border-radius: 10px;
    z-index: 4;
}

.z-999 {
    z-index: 999;
}

.metaverse-iframe {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (9/16 = 0.5625, 4:3 would be 75%) */
    height: 0;
    overflow: hidden;
}

/* Style the iframe to take the full size of the parent container */
.metaverse-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.search-results-container {
    border-radius: 11px;
    padding: 50px 40px;

    .result {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        flex: 2;
    }

    .actions {
        flex: 1;
        display: flex;
        align-items: end;
        justify-content: end;
    }
}

.search-popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999999999999999999;

    display: flex;
    justify-content: center;
    align-items: center;

    .close-btn {
        position: absolute;
        top: 30px;
        right: 30px;
        color: #FFFFFF;
        font-size: 20px;
        line-height: 20px;
        cursor: pointer;
    }
}