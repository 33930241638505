.sticky-toolbar {
  width: 30px;
  position: fixed;
  top: 28%;
  right: 0;
  list-style: none;
  margin: 0;
  z-index: 95;
  background: #B4A663;
  color: #072D1A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;

  li {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 14px;

    svg {
      fill: #072D1A !important;
    }
  }
}
