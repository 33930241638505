.partner-section {
  .bg-charity-logo {

    img {
      width: 100%;
      height: 115px;
      margin: 0 auto;
      object-fit: contain;
    }

    @media (max-width: $breakpoint-767) {
      img {
        height: 50px;
      }
    }
  }

  .goal-logo {
    width: 100px;
    margin: 0 8px;

    @media (max-width: $breakpoint-767) {
      width: 70px;
    }

    @media (max-width: $breakpoint-1199) {
      width: 85px;
    }

    img {
      width: 100%;
    }
  }

  .title-primary-2 {
    text-transform: none;
    font-family: "ClashDisplay-SemiBold", serif;
  }

  .partner-form-container {
    background-color: #F6F3D8;
    //padding: 88px 74px 38px;
    margin-top: 134px;
    margin-bottom: 102px;
    border-radius: 21px;

    .space-wrapper {
      padding: 88px 74px 38px;
    }



    @media (max-width: $breakpoint-991) {
      margin-top: 67px;

      .space-wrapper {
        padding: 34px 15px;
      }

      .form-control, .react-select-container {
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 18px;
    }

    .main-input {
      background-color: transparent !important;

      &::placeholder {
        color: rgba(1, 60, 32, 0.6);
      }
    }

    .react-select-container {
      text-align: left;

      .react-select__control {
        background-color: transparent !important;
        outline: none !important;
        border-color: rgba(190, 171, 146, 0.60);
        border-radius: 8px !important;
        height: 45px;

        &.react-select__control--is-focused {
          outline: none !important;
          border-color: rgba(190, 171, 146, 0.60) !important;
          box-shadow: none !important;
        }

        .css-1u9des2-indicatorSeparator {
          opacity: 0;
        }

        .react-select__value-container {
          .react-select__placeholder {
            color: rgba(1, 60, 32, 0.6);
            font-family: $font-main-text-regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        .react-select__indicator {
          svg {
            fill: rgba(1, 60, 32, 0.6);
          }
        }
      }

      .react-select__menu {
        background-color: #FFFFFF;
        border-color: rgba(0, 0, 0, 0.13);
        width: 25%;
        right: 0;
        left: auto;

        @media (max-width: $breakpoint-991) {
          width: 100%;
        }

        .react-select__menu-list {
          padding: 10px;

          .react-select__option {
            border-radius: 4px;
            font-family: $font-main-text-regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            color: #626262;

            &.react-select__option--is-focused {
              background-color: #F6F3D8;
              color: #184D2D;

              svg {
                fill: #184D2D;
                color: #184D2D;

                path {
                  fill: #184D2D !important;
                  color: #184D2D !important;;
                }
              }
            }

            .svg-icon {
              width: 17px;
              margin-right: 15px !important;

              svg {
                path {
                  fill: #626262;
                  color: #626262;
                }
              }
            }
          }
        }
      }
    }
  }

  .partner-content {
    text-align: center;

    .card-layout {
      padding: 10px;
    }

    .bg-charity-logo {
      img {
        width: 200px;
        height: 185px;
      }
    }

    @media (max-width: $breakpoint-767) {
      .card-layout {
        padding: 5px;
      }

      .bg-charity-logo {
        img {
          width: 115px;
        }
      }
    }

    .main-p {
      h1, h2, h3, h4, h5, h6 {
        color: $primary;
        font-family: $font-main-title-semi-bold;
        font-size: 20px;
        line-height: 24px;
        font-style: normal;
        font-weight: 600;
        margin-top: 30px;

        &:first-child {
          margin-top: 0;
        }

        @media (max-width: $breakpoint-767) {
          font-size: 16px;
          line-height: 20px;
          margin-top: 15px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 15px;

        @media (max-width: $breakpoint-767) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .business-partners-slider, .charity-partners-slider, .campaign-partners-slider {
    &.slick-slider {
      .slick-arrow {
        &:before {
          content: ""
        }

        &.slick-next {
          right: -50px;
        }

        &.slick-prev {
          left: -50px;
        }

        @media (max-width: $breakpoint-767) {
          &.slick-next {
            right: -40px;
          }

          &.slick-prev {
            left: -40px;
          }
        }

        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #7E895B !important;
        color: #FFFFFF;
        text-align: center;

        svg {
          font-size: 28px;
          line-height: 28px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .slick-dots {
      li {
        &.slick-active {
          button {
            &:before {
              color: #164327;
            }
          }
        }

        button {
          &:before {
            color: #FFFFFF;
            opacity: 1;
            font-size: 12px;
          }
        }
      }
    }
  }

  .charity-partners-slider {
    &.slick-slider {
      .slick-arrow {
        background-color: #97A079 !important;
      }
    }
  }

  .campaign-partners-slider {
    &.slick-slider {
      .slick-arrow {
        background-color: #97A079 !important;
      }
    }
  }
}

.form-control, .react-select-container {
  &.selected {
    .react-select__control {
      border-color: #198754 !important;
    }
  }
}

.react-select-container {
  text-align: left;

  .react-select__control {

    border-radius: 8px !important;
    height: 45px;

    &.react-select__control--is-focused {
      outline: none !important;
      border-color: rgba(190, 171, 146, 0.60) !important;
      box-shadow: none !important;
    }

    .css-1u9des2-indicatorSeparator {
      opacity: 0;
    }

    .react-select__value-container {
      .react-select__placeholder {
        font-family: $font-main-text-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .react-select__indicator {
      svg {
        fill: rgba(1, 60, 32, 0.6);
      }
    }
  }

  .react-select__menu {
    background-color: #FFFFFF;
    border-color: rgba(0, 0, 0, 0.13);
    width: 25%;
    right: 0;
    left: auto;

    @media (max-width: $breakpoint-991) {
      width: 100%;
    }

    .react-select__menu-list {
      padding: 10px;

      .react-select__option {
        border-radius: 4px;
        font-family: $font-main-text-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: #626262;

        &.react-select__option--is-focused {
          background-color: #F6F3D8;
          color: #184D2D;

          svg {
            fill: #184D2D;
            color: #184D2D;

            path {
              fill: #184D2D !important;
              color: #184D2D !important;;
            }
          }
        }

        .svg-icon {
          width: 17px;
          margin-right: 15px !important;

          svg {
            path {
              fill: #626262;
              color: #626262;
            }
          }
        }
      }
    }
  }
}