.el-1-left {
  top: 10%;
  left: 0;
  transform: translate(-50%, -50%);
}

.shine {
  bottom: 10%;
  position: absolute;
}

.img-ifa {
  height: 300px;
  object-fit: contain;
}

.custom-container {
  position: relative;
  background-color: transparent;
  border-radius: 10px;
}

.custom-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%; /* Adjust the height of the transparent area */
  background: white;
  border-radius: 10px;
  z-index: 4;
}

.z-999 {
  z-index: 999;
}

.btm-s-s {
  bottom: -50px;
  left: 0;
  z-index: 1;
  width: 100%;

  img {
    width: 70%;
  }
}

.lower-slider {
  border-radius: 20px;
  border: 4px solid #C1C19B;
  background: linear-gradient(#C1B988, #B5B383);
}

.lower-slider-text {
  text-align: start;
  color: #0F2725;
  font-family: $font-secondary-regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px; /* 145% */
}

.signature-slider {
  color: #0F2725;
  text-align: center;
  font-family: $font-secondary-bold;
  font-size: 30px;
  font-style: normal;
  line-height: 53px;
  text-transform: uppercase;
}


.light-theme {
  .slick-dots li button:before {
    color: #fff;

  }

  .slick-list {
    z-index: 999;
  }

  .slick-prev:before {
    content: unset
  }

  .slick-next:before {
    content: unset
  }

  .slick-arrow {
    img {
      width: 20px;
      height: 20px;
    }
  }

  .slick-dots li {
    margin: 0 -4px;
  }
}

.tg-d {
  color: #0F2725;
  font-family: $font-main-text-bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}


.vision {
  .card {
    border: transparent;
    background-color: transparent;
  }

  .card.card-custom.card-stretch.gutter-b {
    height: calc(100% - 30px);
  }

  .card.card-custom.card-stretch {
    display: flex;
    align-items: stretch !important;
    flex-direction: column;
    height: 100%;
  }

  .example.example-compact {
    position: relative;
  }

  .gutter-b {
    margin-bottom: 30px;
  }
}

.right-reserved {
  color: #626262;
  font-family: $font-main-text-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.bdr-orange {
  border: 1px solid #E7C191;
}

.bottom-82 {
  bottom: -50%;
  z-index: 1;

  img {
    width: 500px
  }
}
