.label {
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 0.8rem;
  background-color: #EBEDF3;
  color: #3F4254;
  font-weight: 400;

  &.label-inline {
    width: auto;
    padding: 0.15rem 0.75rem;
    @include border-radius(.42rem);

    // Sizing
    &.label-md {
      padding: 0.8rem 0.6rem;
    }

    &.label-lg {
      padding: 0.9rem 0.75rem;
    }

    &.label-xl {
      padding: 1rem 0.85rem;
    }
  }
}

.label.label-light-info {
  color: #8950FC;
  background-color: #EEE5FF;
}

.label.label-warning {
  color: #ffffff;
  background-color: #FFA800;
}

.label.label-outline-warning {
  background-color: transparent;
  color: #FFA800;
  border: 1px solid #FFA800;
}

.label.label-outline-warning.label-outline-2x {
  border: 2px solid #FFA800;
}

.label.label-light-warning {
  color: #FFA800;
  background-color: #FFF4DE;
}

.label.label-danger {
  color: #ffffff;
  background-color: #F64E60;
}

.label.label-outline-danger {
  background-color: transparent;
  color: #F64E60;
  border: 1px solid #F64E60;
}

.label.label-outline-danger.label-outline-2x {
  border: 2px solid #F64E60;
}

.label.label-light-danger {
  color: #F64E60;
  background-color: #FFE2E5;
}

.label.label-light-success {
  color: #1BC5BD;
  background-color: #C9F7F5;
}

.label.label-inline {
  width: auto;
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
}

.label.label-inline.label-xl {
  padding: 1rem 0.85rem;
}

.label.label-xl.label-inline {
  width: auto;
}