.card-layout {
  border-radius: 21px;
  box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.21);

  &.one {
    background: #CED1AF;
    box-shadow: 0px 30px 64px 0px rgba(7, 7, 7, 0.49);

  }

  &.three {
    background: #F6F3D8;
  }


}

.card {
  border: transparent;
}

.card.card-custom.card-stretch.gutter-b {
  height: calc(100% - 30px);
}

.card.card-custom.card-stretch.gutter-b-2 {
  height: calc(100% - 200px);
}

.card.card-custom.card-stretch {
  display: flex;
  align-items: stretch !important;
  flex-direction: column;
  height: 100%;
}

.card.card-custom {
  //box-shadow: 0px 0px 30px #1D7FAB16;
}

.example.example-compact {
  position: relative;
}

.gutter-b {
  margin-bottom: 30px;
}


.text-center {
  text-align: center;
}

.card-layout-2 {
  border-radius: 21px;
  background: #E2E0C0;
}
.slick-track
{
  display: flex !important;
}

.slick-slide
{
  height: inherit !important;
}