.bg-light-opacity {
  background: #EAC798;
}

.bg-light-2 {
  background: #F6F3D8;
}
.bg-box-shadow-light{
  box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.21);
}
.bg-light-green {
  background: #E0DFBF;
  border-radius: 21px;
  box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.21);
}

.bg-orange {
  background: #EAC798 !important;
}

.bg-split-white {
  background: linear-gradient(180deg, transparent 50%, #F6F3D8 0);
  border-radius: 21px
}

.bg-form {
  background-image: linear-gradient(to bottom, #879D80, #B9B78C, #D4C495, #F0D4A4, #F0D5A6) !important;
}

.bg-form-secondary {
  background-image: linear-gradient(to right, #8A8F67, #879D80, #879D80, #879D80, #879D80);
}

.bg-yellow {
  background: #EDCD72;
}

.bg-council {
  background: #C4CCAF;
}

.bg-charity {
  background: rgba(246, 243, 216, 0.76);
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.21) !important;
}

.bg-charity-logo {
  border-radius: 17px;
  background: #FFF;
}

.bg-primary-color {
  background: $primary !important;
}

.bg-checkout {
  background-color: rgba(246, 243, 216, 0.5);
  border-radius: 21px;
}