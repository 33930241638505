@media (max-width: $breakpoint-991) {
  .h-500px {
    height: auto !important;
  }
  .medium-primary-text {
    font-size: 14px;
    line-height: 18px;
  }
  .h-charity-content {
    height: auto !important;
    min-height: auto !important;
    overflow-y: auto;
  }
  .custom-container::before {
    height: 100% !important;
  }
  .h-800px {
    height: auto !important;
  }
  .shop-search {
    width: 100%;
  }
  .blog-image {
    height: 122.48px;
  }
  .blog-show-image {
    height: auto;
  }
  .mt-300 {
    margin-top: 30px;
  }
  .mt-250 {
    margin-top: 30px;
  }
  .image-blog {
    position: unset !important;
    width: 100%;
    transform: unset !important;
  }

  .links {
    font-size: 16px;
    line-height: 20px;
  }
  .card-layout-1 {
    border-radius: 7px;
  }
  .h-130px {
    height: 120px;
  }
  .h-60px {
    height: auto;
  }
  .main-text {
    font-size: 14px;
    line-height: 20px;
  }
  .main-p-grey {
    font-size: 12px;
  }
  .primary-title {
    font-size: 20px;
  }
  .w-75 {
    width: 55% !important;
  }
  .title-h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 350% */
    letter-spacing: 3.24px;
  }
  .main-title {
    letter-spacing: 0.8px;
    font-size: 30px;

    &.lg {
      font-size: 40px;
      line-height: 44px;
    }
  }
  .title-primary-2 {
    &.lg {
      font-size: 30px;
      line-height: 34px;
    }
  }
  .placeholder-2 {
    top: -22%;
    left: -10%;
    width: 100%;
  }
  .btn {
    &.btn-main,
    &.btn-primary-dark {
      &.lg {
        font-size: 15px;
        letter-spacing: 1.4px;
        line-height: 19px;
      }
    }
  }
  .logo-icon {
    width: 20px;
  }
  .title-h2 {
    font-size: 20px;
    line-height: 25px;
  }

  .arrow-pagination {
    position: unset !important;
    transform: unset !important;

    &.next {
      margin-left: 20px;
    }

    &.prev {
      margin-right: 20px;
    }
  }
}

@media (max-width: 300px) {
  .main-title {
    &.lg {
      font-size: 35px;
      line-height: 39px;
    }
  }
}
