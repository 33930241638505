@mixin switch_to_b4($value) {
  -ms-flex: 0 0 $value;
  flex: 0 0 $value;
  max-width: $value;
}

.col-md-1\/5 {
  position: relative;
  float: left;
  width: 20%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-2\/5 {
  position: relative;
  float: left;
  width: 40%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-3\/5 {
  position: relative;
  float: left;
  width: 60%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-4\/5 {
  position: relative;
  float: left;
  width: 80%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-5\/5 {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-offset-5\/5 {
  margin-left: 100%;
}

.col-md-offset-4\/5 {
  margin-left: 80%;
}

.col-md-offset-3\/5 {
  margin-left: 60%;
}

.col-md-offset-2\/5 {
  margin-left: 40%;
}

.col-md-offset-1\/5 {
  margin-left: 20%;
}

.col-md-offset-0\/5 {
  margin-left: 0%;
}

.col-sm-1\/5, .col-md-1\/5, .col-lg-1\/5, .col-xl-1\/5, .col-sm-2\/5, .col-md-2\/5, .col-lg-2\/5, .col-xl-2\/5, .col-sm-3\/5, .col-md-3\/5, .col-lg-3\/5, .col-xl-3\/5, .col-sm-4\/5, .col-md-4\/5, .col-lg-4\/5, .col-xl-4\/5, .col-sm-5\/5, .col-md-5\/5, .col-lg-5\/5, .col-xl-5\/5 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-sm-1\/5, .col-sm-2\/5, .col-sm-3\/5, .col-sm-4\/5 {
  float: left;
}

.col-sm-5\/5 {
  float: left;
  @include switch_to_b4(100%);
  //width: 100%;
}

.col-sm-4\/5 {
  @include switch_to_b4(80%);
  //width: 80%;
}

.col-sm-3\/5 {
  @include switch_to_b4(60%);
  //width: 60%;
}

.col-sm-2\/5 {
  @include switch_to_b4(40%);
  //width: 40%;
}

.col-sm-1\/5 {
  @include switch_to_b4(100%);
  //width: 20%;
  @media (min-width: 576px) {
    @include switch_to_b4(20%);

  }
}

.col-sm-pull-5\/5 {
  right: 100%;
}

.col-sm-pull-4\/5 {
  right: 80%;
}

.col-sm-pull-3\/5 {
  right: 60%;
}

.col-sm-pull-2\/5 {
  right: 40%;
}

.col-sm-pull-1\/5 {
  right: 20%;
}

.col-sm-pull-0\/5 {
  right: auto;
}

.col-sm-push-5\/5 {
  left: 100%;
}

.col-sm-push-4\/5 {
  left: 80%;
}

.col-sm-push-3\/5 {
  left: 60%;
}

.col-sm-push-2\/5 {
  left: 40%;
}

.col-sm-push-1\/5 {
  left: 20%;
}

.col-sm-push-0\/5 {
  left: auto;
}

.col-sm-offset-5\/5 {
  margin-left: 100%;
}

.col-sm-offset-4\/5 {
  margin-left: 80%;
}

.col-sm-offset-3\/5 {
  margin-left: 60%;
}

.col-sm-offset-2\/5 {
  margin-left: 40%;
}

.col-sm-offset-1\/5 {
  margin-left: 20%;
}

.col-sm-offset-0\/5 {
  margin-left: 0%;
}

.rtl .rtl-columns-reverse {
  .col-sm-1\/5, .col-sm-2\/5, .col-sm-3\/5, .col-sm-4\/5, .col-sm-5\/5 {
    float: right;
  }

  .non_responsive .row {
    .col-md-1\/5, .col-md-2\/5, .col-md-3\/5, .col-md-4\/5, .col-md-5\/5 {
      float: right;
    }
  }
}

@media (min-width: 768px) {
  .col-md-1\/5, .col-md-2\/5, .col-md-3\/5, .col-md-4\/5, .col-md-5\/5 {
    float: left;
  }
  .rtl .rtl-columns-reverse {
    .col-md-1\/5, .col-md-2\/5, .col-md-3\/5, .col-md-4\/5, .col-md-5\/5 {
      float: right;
    }
  }
  .col-md-5\/5 {
    @include switch_to_b4(100%);
    //width: 100%;
  }
  .col-md-4\/5 {
    @include switch_to_b4(80%);
    //width: 80%;
  }
  .col-md-3\/5 {
    @include switch_to_b4(60%);
    //width: 60%;
  }
  .col-md-2\/5 {
    @include switch_to_b4(40%);
    //width: 40%;
  }
  .col-md-1\/5 {
    @include switch_to_b4(20%);
    //width: 20%;
  }
  .col-md-pull-5\/5 {
    right: 100%;
  }
  .col-md-pull-4\/5 {
    right: 80%;
  }
  .col-md-pull-3\/5 {
    right: 60%;
  }
  .col-md-pull-2\/5 {
    right: 40%;
  }
  .col-md-pull-1\/5 {
    right: 20%;
  }
  .col-md-pull-0\/5 {
    right: auto;
  }
  .col-md-push-5\/5 {
    left: 100%;
  }
  .col-md-push-4\/5 {
    left: 80%;
  }
  .col-md-push-3\/5 {
    left: 60%;
  }
  .col-md-push-2\/5 {
    left: 40%;
  }
  .col-md-push-1\/5 {
    left: 20%;
  }
  .col-md-push-0\/5 {
    left: auto;
  }
  .col-md-offset-5\/5 {
    margin-left: 100%;
  }
  .col-md-offset-4\/5 {
    margin-left: 80%;
  }
  .col-md-offset-3\/5 {
    margin-left: 60%;
  }
  .col-md-offset-2\/5 {
    margin-left: 40%;
  }
  .col-md-offset-1\/5 {
    margin-left: 20%;
  }
  .col-md-offset-0\/5 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {
  .col-lg-1\/5, .col-lg-2\/5, .col-lg-3\/5, .col-lg-4\/5, .col-lg-5\/5 {
    float: left;
  }
  .rtl .rtl-columns-reverse {
    .col-md-1\/5, .col-md-2\/5, .col-md-3\/5, .col-md-4\/5, .col-md-5\/5 {
      float: right;
    }
  }
  .col-lg-5\/5 {
    @include switch_to_b4(100%);
    //width: 100%;
  }
  .col-lg-4\/5 {
    @include switch_to_b4(80%);
    //width: 80%;
  }
  .col-lg-3\/5 {
    @include switch_to_b4(60%);
    //width: 60%;
  }
  .col-lg-2\/5 {
    @include switch_to_b4(40%);
    //width: 40%;
  }
  .col-lg-1\/5 {
    @include switch_to_b4(20%);
    //width: 20%;
  }
  .col-lg-pull-5\/5 {
    right: 100%;
  }
  .col-lg-pull-4\/5 {
    right: 80%;
  }
  .col-lg-pull-3\/5 {
    right: 60%;
  }
  .col-lg-pull-2\/5 {
    right: 40%;
  }
  .col-lg-pull-1\/5 {
    right: 20%;
  }
  .col-lg-pull-0\/5 {
    right: auto;
  }
  .col-lg-push-5\/5 {
    left: 100%;
  }
  .col-lg-push-4\/5 {
    left: 80%;
  }
  .col-lg-push-3\/5 {
    left: 60%;
  }
  .col-lg-push-2\/5 {
    left: 40%;
  }
  .col-lg-push-1\/5 {
    left: 20%;
  }
  .col-lg-push-0\/5 {
    left: auto;
  }
  .col-lg-offset-5\/5 {
    margin-left: 100%;
  }
  .col-lg-offset-4\/5 {
    margin-left: 80%;
  }
  .col-lg-offset-3\/5 {
    margin-left: 60%;
  }
  .col-lg-offset-2\/5 {
    margin-left: 40%;
  }
  .col-lg-offset-1\/5 {
    margin-left: 20%;
  }
  .col-lg-offset-0\/5 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  .col-xl-1\/5, .col-xl-2\/5, .col-xl-3\/5, .col-xl-4\/5, .col-xl-5\/5 {
    float: left;
  }
  .rtl .rtl-columns-reverse {
    .col-md-1\/5, .col-md-2\/5, .col-md-3\/5, .col-md-4\/5, .col-md-5\/5 {
      float: right;
    }
  }
  .col-xl-5\/5 {
    @include switch_to_b4(100%);
  }
  .col-xl-4\/5 {
    @include switch_to_b4(80%);
  }
  .col-xl-3\/5 {
    //width: 60%;
    @include switch_to_b4(60%);
  }
  .col-xl-2\/5 {
    //width: 40%;
    @include switch_to_b4(40%);
  }
  .col-xl-1\/5 {
    //width: 20%;
    @include switch_to_b4(20%);
  }
  .col-xl-pull-5\/5 {
    right: 100%;
  }
  .col-xl-pull-4\/5 {
    right: 80%;
  }
  .col-xl-pull-3\/5 {
    right: 60%;
  }
  .col-xl-pull-2\/5 {
    right: 40%;
  }
  .col-xl-pull-1\/5 {
    right: 20%;
  }
  .col-xl-pull-0\/5 {
    right: auto;
  }
  .col-xl-push-5\/5 {
    left: 100%;
  }
  .col-xl-push-4\/5 {
    left: 80%;
  }
  .col-xl-push-3\/5 {
    left: 60%;
  }
  .col-xl-push-2\/5 {
    left: 40%;
  }
  .col-xl-push-1\/5 {
    left: 20%;
  }
  .col-xl-push-0\/5 {
    left: auto;
  }
  .col-xl-offset-5\/5 {
    margin-left: 100%;
  }
  .col-xl-offset-4\/5 {
    margin-left: 80%;
  }
  .col-xl-offset-3\/5 {
    margin-left: 60%;
  }
  .col-xl-offset-2\/5 {
    margin-left: 40%;
  }
  .col-xl-offset-1\/5 {
    margin-left: 20%;
  }
  .col-xl-offset-0\/5 {
    margin-left: 0%;
  }
}