.intro-home {
  width: 445px !important;

  @media (max-width: $breakpoint-767) {
    width: 260px !important;
    margin: 0 auto;
  }
}

.bg {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: -1;

  .mask-1 {
    //width: 500px;
    position: absolute;
    top: -145px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    object-fit: cover;
    height: auto;
  }

  .mask-2 {
    width: 540px;
    position: absolute;
    top: -100px;
    left: -175px;
    //width: 100%;
    //object-fit: cover;
    //height: auto;
  }
  
  @media (max-width: $breakpoint-1199) {
    .mask-1 {
      width: 100%;
    }

    .mask-2 {
      left: -125px;
    }
  }

  @media (max-width: $breakpoint-767) {
    .mask-1 {
      width: 250px;
      top: -75px;
    }

    .mask-2 {
      width: 350px;
      left: -70px;
    }
  }
}

.placeholder-1 {
  position: absolute;
  z-index: -1;
  top: -30%;
  width: 100%;
}

.placeholder-3 {
  position: absolute;
  z-index: -1;
  top: 30%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 100%;

  &.top-10 {
    top: 10%
  }

  &.top-20 {
    top: 20%;
  }

  &.top-40 {
    top: 40%;
  }
}

.placeholder-2 {
  position: absolute;
  z-index: -1;
  top: -50%;
  left: -52%;
  //width: 945px;
}


.rhombus-top {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, -50%);

  .polygon {
    display: inline-block;
    position: relative;
    width: 210px;
    height: 190px;
    background: $white;
    box-sizing: border-box;
    -webkit-clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
    clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
  }

  .polygon img {
    position: absolute;
    top: 7px;
    left: 10px;
    width: 190px;
    height: 175px;
    object-fit: contain;
    -webkit-clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
    clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
  }
}


.rhombus {
  .polygon {
    display: inline-block;
    position: relative;
    width: 112px;
    height: 106px !important;
    background: $white;
    box-sizing: border-box;
    -webkit-clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
    clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
  }

  .polygon img {
    position: absolute;
    top: 7px;
    left: 8px;
    width: 96px;
    height: 93px;
    object-fit: cover;
    clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
  }
}

.image-blog {
  position: absolute;
  width: 90%;
  top: 5%;
  transform: translate(-50%, -50%);
  left: 50%;
}


.hexagon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hexagon {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: white;
  clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hexagon-image {
  max-width: 50%;
  max-height: 50%;
  object-fit: contain;
}

.blog-image {
  height: 200px;
  object-fit: cover;
}

.blog-show-image {
  height: 400px;
  object-fit: cover;
}

.project-image {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.img-ifa {
  height: 300px;
  object-fit: contain;
}