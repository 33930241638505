.search-input {
  border-radius: 80px;
  background: #FFF;
  color: #142824;
  font-family: $font-main-text-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 261.538% */
  text-transform: capitalize;
}

.form-select {
  color: $primary;
  font-family: $font-main-title-semi-bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
  background-color: #EAC798;
  border: 2px solid #F0D8B7;
}

.main-input {
  border-radius: 8px;
  border: 1px solid rgba(190, 171, 146, 0.60);
  background: #FFF;
  color: #678A79;
  font-family: $font-main-text-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  height: 45px;

  &.rounded {
    border-radius: 80px !important;
  }
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #0000;
  border: 1px solid #626262;
  border-radius: 0;
  cursor: pointer;
  height: 15px;
  margin-right: 5px;
  position: relative;
  vertical-align: middle;
  width: 15px;
}

.custom-checkbox:before {
  border: solid #626262;
  border-width: 0 2px 2px 0;
  content: "";
  display: inline-block;
  height: 10px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
}

.custom-checkbox:checked:before {
  opacity: 1;
}

.shop-search {
  width: 50%;
  position: relative;

  input {
    background: #edcd7270;
    border-radius: 11px;
    font-size: 14px;
    font-family: $font-main-title-regular;
    text-transform: capitalize;
    color: $white;
    height: 45px;
    border: unset;

    &::placeholder {
      color: $white
    }

    &:focus {
      background: #edcd7270;
      color: $white
    }
  }

  .icon-search {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translate(-50%, -50%);

    i {
      color: #ACB390;
      font-size: 14px;
    }
  }
}

.modal-content {
  background: #F6F3D8;
}

.modal-header {
  border-bottom: unset !important;
}

.form-control {
  &.form-control-search {
    &:focus {
      box-shadow: none !important;
      border: 1px solid #FFF !important;
    }
  }

  &.gol-file-input {
    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    &::before {
      white-space: nowrap;
      -webkit-user-select: none;
      content: "Presentation Deck";
      display: inline-block;
      background: transparent;
      outline: none;
      cursor: pointer;
      vertical-align: middle;
      padding: 6px 10px 6px 0;
      border-right: 1px solid rgba(190, 171, 146, 0.60);
      //color: #678A79;
      font-family: $font-main-text-regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &:hover {
      &::before {
        border-color: #678A79;
      }
    }

    &:active {
      &::before {
        background: transparent !important;
      }
    }
  }
}