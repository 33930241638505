.select-light-active {
  .custom-select {
    border: 1px solid rgba(12, 67, 41, 0.20);
    background: #F1EFD4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 4px 43px 0px rgba(0, 0, 0, 0.25);

  }

  .arrow-icon {
    color: $primary;
  }
}


.option-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: contain;
}


.select-transparent {
  color: $white;

  .custom-select {
    border: 1px solid #4F6C4B;
    cursor: pointer;
    border-radius: 5px;
  }

  .arrow-icon {
    color: #EAC798;
  }
}


.select-container {
  position: relative;
  width: 100%;
  border-radius: 5px;

  .custom-select {
    padding: 10px;
    cursor: pointer;
    font-family: $font-main-title-regular;
    font-size: 14px;
    font-weight: 500;

  }

  .custom-select-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #C5D3BF;
    border-top: none;
    background: #F6F3D8;
    box-shadow: 0px 4px 43px 0px rgba(0, 0, 0, 0.25);
    max-height: 350px;
    overflow-y: auto;
    z-index: 1;

    font-family: $font-main-title-bold;
    font-size: 15px;
    font-style: normal;
    letter-spacing: 1.05px;
    text-transform: uppercase;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .custom-select-option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #C5D3BF;
    border-top: none;
  }

  .custom-select-option:hover {
    background-color: #f0f0f0;
  }

  .arrow-icon {
    margin-left: 10px;
    transition: transform 0.2s ease;
  }

  .arrow-icon.open {
    transform: rotate(180deg);
  }
}

.css-13cymwt-control {
  border-radius: 8px !important;
  border: 1px solid rgba(190, 171, 146, 0.60);
  background: #FFF;
  color: #678A79;
  font-family: $font-main-text-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  height: 45px;

}

.css-t3ipsp-control {
  border-radius: 8px;
  border: 1px solid rgba(190, 171, 146, 0.60);
  background: #FFF;
  color: #678A79;
  font-family: $font-main-text-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  height: 45px;
}

.invalid-select {
  border-radius: 8px;
  border: 1px solid var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  &.bg-white {
    background-color: #fff;
  }
}

.valid-select {
  border-radius: 8px;
  border: 1px solid var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  &.bg-white {
    background-color: #fff;
  }
}