.main-title {
  color: #fff;
  text-align: center;
  font-family: $font-main-title-bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 50px;

  &.lg {
    font-size: 70px;
    line-height: 65px;
  }
}

.title-h6 {
  color: #fff;
  text-align: center;
  font-family: $font-main-title-regular;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 5.04px;
  text-transform: uppercase;
}

.lh-29 {
  line-height: 29px !important;
}
.title-h2 {
  color: #fff;
  font-family: $font-main-title-bold;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 53px; /* 106% */
  text-transform: uppercase;
  letter-spacing: 3px;
}

.primary-text {
  color: $primary !important;
}

.ff-main-title-semi {
  font-family: $font-main-title-semi-bold !important;
}

.main-text {
  color: #fff;
  font-family: $font-main-text-medium;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
}

.main-text-primary {
  color: $primary;
  font-family: $font-main-text-semi-bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 188.889% */
  letter-spacing: -0.408px;
  text-transform: capitalize;
}

.text-normal {
  text-transform: unset !important;
}

.secondary-text {
  color: $secondary-text-color;
}

.primary-title {
  color: $primary;
  text-align: center;
  font-family: $font-main-title-semi-bold;
  font-size: 25px;
  font-style: normal;
  line-height: 30px; /* 160% */
  text-transform: uppercase;
  letter-spacing: 1px;

  &.lh-sm {
    line-height: 10px !important;
  }
}
.medium-primary-text {
  color: $primary;
  text-align: center;
  font-family: $font-main-title-medium;
  font-size: 25px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 1px;
}

.main-p {
  text-align: center;
  font-family: $font-main-text-medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  &.text-color {
    color: $text-color;
  }

  &.secondary-color {
    color: $secondary-text-color;
  }

  &.primary-color {
    color: $primary;
  }

  &.circle-checklist {
    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 10px !important;
        padding-left: 25px !important;
        position: relative;

        &::before {
          color: $primary;
          content: "";
          display: block;
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          left: 0px;
          position: absolute;
          top: 1px;
        }
      }
    }
  }
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.ff-main-text-regular {
  font-family: $font-main-text-regular;
}

.text-underlined {
  text-decoration: underline;
}
.steps-text {
  color: #0f2725;
  font-family: $font-main-text-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.fs-40 {
  font-size: 40px;
}

.f-bold {
  font-family: $font-main-title-bold;
}

.f-regular {
  font-family: $font-main-title-regular !important;
}

.text-light-green {
  color: #0d492d;
  text-align: center;
  font-family: $font-secondary-bold;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 222.222% */
  text-transform: uppercase;
}

.breadcrumb-item {
  color: $white;
  text-align: center;
  font-family: $font-main-title-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 7px;
  padding-right: 4px;
  letter-spacing: 2px;

  &.active {
    color: #94a49c;
  }
}
.letter-spacing {
  letter-spacing: 1px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: $white;
  padding-right: 7px;
}

.title-primary-2 {
  color: $primary;
  text-align: center;
  font-family: $font-main-title-bold;
  font-size: 20px;
  font-style: normal;
  line-height: 24px; /* 160% */
  text-transform: uppercase;
  letter-spacing: 1px;

  &.lg {
    font-size: 50px;
    line-height: 53px;
  }
}

.faq-search {
  input {
    border-radius: 60px;
    border: 1px solid #fff;
    background: #fff;
    color: #142824;
    font-family: $font-main-text-regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 261.538% */
    text-transform: capitalize;

    &:focus {
      box-shadow: none !important;
      border: 1px solid #fff !important;
    }
  }

  button {
    background: #fff;
    color: #0d492d;
    border-top-right-radius: 60px !important;
    border-bottom-right-radius: 60px !important;
    font-size: 18px;
  }
}

.text-primary-chart {
  color: #000 !important;
}

.apexcharts-legend {
  display: block !important;
}

.apexcharts-legend-marker {
  margin-top: 10px !important;
}

.apexcharts-legend-text {
  margin-top: 10px !important;
}

.text-council-description {
  color: $text-color;
  line-height: 22px;
}

.text-sm-name {
  color: $primary;
  font-family: $font-main-title-semi-bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 2px;
}

.content-main {
  color: #626262;
  font-family: $font-main-text-regular;
  font-size: 14px;
  font-style: normal;
  line-height: 23px;

  p {
    color: #626262;
    font-family: $font-main-text-regular;
    font-size: 14px;
    font-style: normal;
    line-height: 23px;
  }

  h1,
  h2,
  h3,
  h4,
  h6,
  b,
  strong {
    color: $primary;
    font-family: $font-main-title-bold;
    letter-spacing: 1px;
    font-size: 18px;
  }

  a {
    color: $primary !important;
    text-decoration: underline;
    text-decoration-color: $primary !important;
    font-family: $font-main-text-bold;
  }

  img {
    width: 100% !important;
  }
}

.tags {
  list-style: none;
  display: inline-flex;
  color: $text-color;
  text-align: center;
  font-family: $font-main-text-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  flex-wrap: wrap;
  justify-content: center;

  li {
    margin: 2px 5px;
    background: #dfe2c7;
    border-radius: 5px;
    padding: 2px 10px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.mini-title-light {
  color: #fff;
  font-family: $font-main-title-light;
  font-size: 25px;
  line-height: 27px;
  text-transform: uppercase;
  letter-spacing: 2px;

  &.bold {
    font-family: $font-main-title-bold;
  }
}

.primary-color {
  color: $primary;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2.2px;
}

.letter-spacing-- {
  letter-spacing: -0.408px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-25 {
  font-size: 25px;
}
.fs-35 {
  font-size: 35px;
}
.fs-14 {
  font-size: 14px;
}

.fv-plugins-message-container {
  color: #dc3545;
  font-family: $font-main-text-regular;
  font-size: 12px;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-transform-none {
  text-transform: none;
}
.link-ifa {
  color: #fff;
  text-align: center;
  font-family: $font-main-title-semi-bold;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px; /* 383.333% */
  letter-spacing: 0.84px;
  text-decoration-line: underline;
  text-transform: uppercase;
}
