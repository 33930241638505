.slick-slide {
  padding-right: 10px;
  padding-left: 10px;
}

.slick-prev:before, .slick-next:before {
  color: #7E895B;
  opacity: 0.75;
}

.slick-dots li {
  margin: 0 !important;
}

.images-slider {
  .slick-prev {
    left: 7%;
    z-index: 9999;
  }

  .slick-next {
    right: 7%;
  }

  .slick-dots {
    bottom: -7%;
  }
}

.slick-dots li button:before {
  font-size: 12px;
}
.council-section{
  .slick-slide{
    div:first-child {
      height: 100%;
    }
  }
}


.council-name{
  position: absolute;
  top: 70%;
  width: 90%;
}