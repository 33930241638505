.separator-1 {
  border: 1px solid #6F8C6E
}

.line-primary {
  border: 1px solid $primary;

}

.separator-2 {
  border: 1px solid #838173;
}
.dark-separataor{
  border-top: 1px solid  #333;
}