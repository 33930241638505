footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 290px; /* Set the fixed height of the footer here */
  $elements_max_width: 270px;
  //@media (max-width: $breakpoint-767) {
  //  height: auto;
  //  position: static;
  //}
  @media (max-width: $breakpoint-991) {
    height: auto;
    position: static;
  }
  //@media (max-width: $breakpoint-991) {
  //  height:820px;
  //}

  .social-links {
    @include reset-padding-margin;

    li {
      margin-top: 10px;
      color: $secondary-text-color;
      margin-right: 15px;
      @include inline-li;
      font-size: 13px;
      text-transform: uppercase;
      @include transition-all(0.3s);


      svg {
        fill: $secondary-text-color;
      }
    }
  }

  .list-links {
    @include reset-padding-margin;

    li {
      color: $secondary-text-color;
      font-family: $font-main-text-regular;
      font-size: 13px;
      font-style: normal;
      line-height: 24px;
      text-transform: uppercase;
      list-style: none;
    }
  }

  .signature-strip-container {
    position: absolute;
    bottom: -15px;
    width: 100%;
    background-color: #000000;
    padding: 5px 0;
    text-align: center;
    font-family: $font-main-text-regular;
    font-size: 10px;
    font-style: normal;
    line-height: 14px;
    color: $light-subtitle-color;

    a {
      opacity: 0.75;
    }
  }
}