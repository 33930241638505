.main-pagination {
  display: inline-flex;
  list-style: none;
  justify-content: center;

  li:not(:first-child):not(:last-child) {
    margin-top: 10px;
    border: 1px solid $white;
    border-radius: 50%;
    background: $white;
    height: 10px;
    margin-left: 2px;
    margin-right: 2px;
    width: 10px;
    color: transparent;
  }

  .active {
    background: $primary !important;
    border: 1px solid $primary !important;
  }

  .arrow-pagination {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &.next, &.prev {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #7E895B !important;
      color: #FFFFFF;
      text-align: center;
    }

    &.next {
      right: -3%;
    }

    &.prev {
      left: -1%;
    }
  }
}