.preview-panel {
  button {
    &.toggle {
      position: fixed;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding: 30px 15px;
      border-radius: 0 !important;
      font-size: 1rem;
      z-index: 999999999999;
      transition: all 0.1s;
      border: unset;
      color: $primary;

      background: linear-gradient(to bottom, #879D80, #B9B78C, #D4C495, #F0D4A4, #F0D5A6);

      &:hover {

        background: #C4CCAF;
      }
    }
  }

  .preview-panel-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.9);
    opacity: 1;
    overflow-y: scroll;
    transition: transform 0.75s ease-in-out;
    transform: translateX(-100%);

    .wrapper {
      padding: 70px 0;
      @media (max-width: $breakpoint-575) {
        padding: 30px 0;
      }
    }

    .block {
      padding: 15px;
      text-align: center;
      border-radius: 5px;
      width: 100%;
      height: 100%;

      .thumbnail {
        margin-bottom: 20px;

        img {
          width: 100%;
        }
      }

      h2 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
      }

      a {
        margin: 0 auto;
      }
    }
  }

  &.expanded {
    .preview-panel-content {
      transform: translateX(0);
    }
  }
}
