.cart-container {
  .cart-list-section {
    .order-summary-container {
      background-color: #F6F3D8;
      border-radius: 21px;
      padding: 20px;

      h6 {
        font-family: $font-main-title-bold;
        font-size: 25px;
        line-height: 53px;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;
        letter-spacing: 1px;

        span {
          font-family: $font-main-title-medium;
          font-size: 13px;
          line-height: 53px;
          font-weight: 500;
          color: $primary;
          text-transform: none;
        }
      }

      @media (max-width: $breakpoint-767) {
        h6 {
          font-size: 20px;
          line-height: 25px;
        }

        span {
          font-size: 13px;
          line-height: 19px;
        }
      }

      .order-summary-table {
        .order-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid #D9D9D9;

          .order-item {
            flex: 1;

            &:first-child {
              flex: 2
            }

            &:nth-child(2) {
              display: flex;
              justify-content: center;
              flex: 1.5;
            }

            &:last-child {
              display: flex;
              justify-content: flex-end;
              flex: 0.5;
            }

            .product {
              .product-img {
                background-color: #FFFFFF;
                border-radius: 4px;
                display: inline-block;
                vertical-align: middle;
                width: 64px;
                height: 60px;
                padding: 10px;
                margin-right: 15px;
                text-align: center;

                img {
                  width: 46px;
                  height: 46px;
                  object-fit: contain
                }

                @media (max-width: $breakpoint-767) {
                  width: 40px;
                  height: 35px;
                  padding: 5px;

                  img {
                    height: 26px;
                  }
                }
              }

              .product-name {
                display: inline-block;
                vertical-align: middle;
                text-align: left;
                font-family: $font-main-title-medium;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: $primary;

                span {
                  display: block;
                }

                @media (max-width: $breakpoint-1199) {
                  font-size: 14px;
                  line-height: 14px;

                  span {
                    font-size: 13px;
                    line-height: 14px;
                  }
                }
              }
            }



            .price {
              font-family: $font-main-title-medium;
              font-size: 16px;
              line-height: 28px;
              font-weight: 500;
              color: $primary;

              @media (max-width: $breakpoint-1199) {
                font-size: 14px;
                line-height: 20px;
              }
            }

            .delete-btn {
              cursor: pointer;
            }
          }
        }

        .order-summary-table-header {
          .order-row {
            padding: 0;

            .order-item {
              font-family: $font-main-title-medium;
              font-size: 18px;
              line-height: 53px;
              font-weight: 500;
              color: $primary;

              @media (max-width: $breakpoint-767) {
                font-size: 15px;
                line-height: 30px;
              }
            }
          }
        }

        .order-summary-table-body {
          border-bottom: 1px solid #D9D9D9;
          padding-bottom: 15px;

          .order-row {
            border: none;
          }
        }

        .order-summary-table-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 15px;

          .btn {
            flex: 2;
          }

          .item {
            flex: 1;
            font-family: $font-main-title-semi-bold;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            color: #0F2725;
            display: flex;
            justify-content: flex-end;
            align-self: flex-start;

            @media (max-width: $breakpoint-1199) {
              font-size: 14px;
              line-height: 14px;

              &:first-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .purchase-section, .login-checkout-section, .user-details-section {
    h6 {
      font-family: $font-main-title-bold;
      font-size: 25px;
      line-height: 53px;
      font-weight: 700;
      color: #FFFFFF;
      text-transform: uppercase;
      margin: 0;
      letter-spacing: 1px;
    }

    p {
      font-family: $font-main-text-medium;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .user-options {
      padding: 2px 40px 35px 40px;

      .or {
        font-family: $font-main-title-bold;
        font-size: 25px;
        line-height: 53px;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  .login-checkout-section {
    .user-options {
      padding: 20px;

      .form-group {
        margin-bottom: 10px;
      }
    }
  }

  .user-details-section {
    form {
      background-color: rgba(246, 243, 216, 0.5);
      border-radius: 21px;
      padding: 15px 40px;

      .form-control {
        &::placeholder {
          color: rgba(1, 60, 32, 0.6);
        }
      }

      h6, p {
        color: #0F2725;
      }

      label {
        font-family: $font-main-text-medium;
        font-size: 14px;
        line-height: 34px;
        font-weight: 500;
        color: $primary;

        padding: 10px 5px;
      }

      .form-control-checkbox {
        margin-right: 10px;
      }

      hr {
        border-color: $primary !important;
        opacity: 1;
      }

      .product-block {
        .product-name {
          font-family: $font-main-title-bold;
          font-size: 25px;
          line-height: 53px;
          font-weight: 700;
          color: $primary;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        .product-label-block {
          .product-label {
            font-family: $font-main-title-medium;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            color: $primary;
          }
        }
      }
    }
  }
}

.quantity-counter {
  span {
    display: inline-block;
    width: 28px;
    height: 28px;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.22);
    text-align: center;
    font-family: $font-main-title-medium;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: $primary;
    margin: 0 15px;

    @media (max-width: $breakpoint-767) {
      width: 20px;
      height: 20px;
      font-size: 14px;
      line-height: 20px;
      margin: 0 10px;
    }
  }

  button {
    border: none;
    background: none;
    text-align: center;
    font-family: $font-main-title-medium;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: $primary;
    padding: 0;

    @media (max-width: $breakpoint-767) {

    }
  }
}