.circle-checklist {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;
    padding-left: 25px;
    position: relative;

    &::before {
      color: $primary;
      content: "";
      display: block;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      left: 0px;
      position: absolute;
      top: 1px;
    }
  }
}