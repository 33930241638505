.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-btn-icon: unset !important;
  --bs-accordion-btn-icon-width: 0 !important;
}

.accordion-item .accordion-button.collapsed {
  color: #FFF;
}

.accordion-header {
  font-family: $font-main-title-bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 180% */
  text-transform: uppercase;
}

.accordion-item {
  border-left: unset !important;
  border-right: unset !important;
  border: unset !important;
  border-radius: 0 !important;
}

.accordion-button:not(.collapsed) {
  background-color: hsla(0, 0%, 85%, .2);
  box-shadow: none;
  color: #0F2725;
  font-family: $font-main-title-bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 180% */
  text-transform: uppercase;
  padding-bottom: 0;
}

.accordion-item .accordion-collapse {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  background: #F6F3D8;;
  //box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.21);
}


.accordion-body {
  color: #707070;
  font-family: $font-main-text-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
  padding-bottom: 30px;
}

.accordion-item .accordion-button:not(.collapsed) {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  background: #F6F3D8;
  //box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.21);
}

.accordion-button {
  padding-top: 30px;
  padding-left: 40px;
}

.accordion-button:focus {
  box-shadow: unset !important;

}

.custom-accordion-header {
  position: relative;
}

.custom-accordion-header::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 55%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 7px; /* Changed border-width to position arrow to the right */
  border-color: transparent transparent transparent #fff; /* Changed border-color to position arrow to the right */
  transition: transform 0.3s; /* Add transition for smooth rotation */
}

/* Rotate arrow when accordion is open */
.custom-accordion-header.accordion-open::before {
  transform: translateY(50%) rotate(180deg); /* Rotate arrow to point down */
  border-color: transparent transparent #000 transparent;
  border-width: 0 7px 7px 7px;
  z-index: 999; /* Change arrow color */
}


/* Hide default arrow */
.uncontrolled-accordion .accordion-button::after {
  display: none;
}

.cursor-pointer {
  cursor: pointer
}

.accordion-open {
  .accordion-button {
    border-radius: 11px 11px 0 0 !important;
    background: #F6F3D8;
    color: #0F2725 !important;
    padding-bottom: 0 !important;
  }
}

.accordion-close {
  .accordion-button {
    border-radius: 0 0 11px 11px !important;
    background: transparent !important;
    color: #fff !important;
    padding-bottom: 1rem !important;
  }
}
