.rs-picker-menu {
  z-index: 9999 !important;
}

.rs-picker-toggle-textbox {
  //padding-left: 13px;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  font-family: $font-main-text-regular;
  font-style: normal;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  font-family: $font-main-text-regular;
  font-style: normal;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  font-family: $font-main-text-regular;
  font-style: normal;
  //color:  rgba(255, 255, 255, 0.42) !important;
}

.rs-anim-fade.rs-anim-in {
  z-index: 9999999999 !important;
}