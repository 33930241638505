/* ClashDisplay-Bold */
@font-face {
  font-family: 'ClashDisplay-Bold';
  src: url('../../fonts/ClashDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* ClashDisplay-Semibold */
@font-face {
  font-family: 'ClashDisplay-SemiBold';
  src: url('../../fonts/ClashDisplay-Semibold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* ClashDisplay-Medium */
@font-face {
  font-family: 'ClashDisplay-Medium';
  src: url('../../fonts/ClashDisplayMedium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* ClashDisplay-Light */
@font-face {
  font-family: 'ClashDisplay-Light';
  src: url('../../fonts/ClashDisplayLight.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* ClashDisplay-Regular */
@font-face {
  font-family: 'ClashDisplay-Regular';
  src: url('../../fonts/ClashDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* ClashDisplayLight */
@font-face {
  font-family: 'ClashDisplayLight';
  src: url('../../fonts/ClashDisplayLight.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* ClashDisplayMedium */
@font-face {
  font-family: 'ClashDisplayMedium';
  src: url('../../fonts/ClashDisplayMedium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* Montserrat-Bold */
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../fonts/Montserrat-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* Montserrat-Medium */
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../../fonts/Montserrat-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* Montserrat-Regular */
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../fonts/Montserrat-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* Montserrat-SemiBold */
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../../fonts/Montserrat-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* Rajdhani-Bold */
@font-face {
  font-family: "Rajdhani Bold";
  src:  url("../../fonts/Rajdhani-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Rajdhani-Medium */
@font-face {
  font-family: 'Rajdhani-Medium';
  src: url('../../fonts/Rajdhani-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}

/* Rajdhani-Regular */
@font-face {
  font-family: 'Rajdhani Regular';
  src: url('../../fonts/Rajdhani-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}
