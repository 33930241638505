.btn {
  text-align: center;
  font-family: $font-main-text-regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  outline: unset !important;

  &.mini-btn {
    background: $white !important;

    &:hover {
      background: $primary !important;
      color: $white !important;
    }
  }

  &:first-child:active, &.active, &.show {
    border: unset !important;
  }

  &.btn-main {
    &.bg-light-green {
      background: #42481D;
    }

    &.btn-light-main {
      background: rgba(75, 40, 0, 0.63) !important;
    }

    color: #FFFAF1 !important;
    position: relative;
    background: linear-gradient(to right, #223517, #203316, #203416);
    border-radius: 50px;
    letter-spacing: 1.05px;
    font-family: $font-main-title-semi-bold;

    &:before {
      background: linear-gradient(93.09deg, #F8C928, #D09E38, #A24505) border-box;
      border: 3.5px solid transparent;
      border-radius: 50px;
      bottom: 0;
      content: "";
      left: 0;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      position: absolute;
      right: 0;
      top: 0;

    }

    &.md {
      font-size: 18px;
      padding: 15px 0;
    }

    &.lg {
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 1.61px;
      padding: 10px 0;
    }

    &.sm {
      padding: 10px 30px;
    }

    .logo-icon {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }

    &:active {
      border: 1px solid transparent !important;
    }
  }

  &.btn-select {
    color: #FFFAF1 !important;
    border-radius: 60px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(75, 40, 0, 0.00);
  }

  &.btn-primary-dark {
    border: 4px solid #013B20;
    border-radius: 50px;
    background-color: #0D492D;
    color: #FFF;
    line-height: 20px; /* 133.333% */
    letter-spacing: 1.05px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: $font-main-title-semi-bold;
    //height: 45px;
    .logo-icon {
      width: 20px;
    }

    &:hover {
      border: 4px solid #013B20;
      background-color: #fff;
      color: #0D492D;
    }

    &:active {
      border: 4px solid #013B20 !important;
      background-color: #fff;
      color: #0D492D;
    }

    &.md {
      font-size: 18px;
      // padding: 15px 0;
    }

    &.lg {
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 1.61px;
      padding: 10px 0px;
    }
  }

  &.btn-link-dark {
    color: $primary;
    font-family: $font-main-title-semi-bold;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    line-height: 46px;
    letter-spacing: 0.98px;
    text-decoration-line: underline;
    text-transform: uppercase;

    &:active, &:hover {
      border-color: transparent !important;
    }


  }
}

.link {
  color: #71907F;
  text-align: center;
  font-family: $font-main-title-semi-bold;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 130.435% */
  text-decoration-line: underline;
  text-transform: uppercase;

  &.sm {
    text-transform: none !important;
    font-size: 18px;
    line-height: 22px;
  }

  &.active {
    color: #fff;
  }

  &:hover {
    color: #fff;
  }
}

.overlay-button {
  position: relative;
  background-color: #41481D;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s;
}

.overlay-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(65, 72, 29); /* Overlay color with opacity */
  opacity: 0;
  transition: opacity 0.3s;
}

.overlay-button:hover::before {
  opacity: 1;
}

.overlay-button:hover {
  color: #f0f0f0; /* Optional: Change text color on hover */
}

.main-link {

}