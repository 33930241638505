$breakpoint-1199: 1199px;
$breakpoint-991: 991px;
$breakpoint-767: 767px;
$breakpoint-575: 575px;


$font-main-title-light: "ClashDisplay-Light", serif;
$font-main-title-bold: "ClashDisplay-Bold", serif;
$font-main-title-regular: "ClashDisplay-Regular", serif;
$font-main-title-medium: "ClashDisplay-Medium", serif;
$font-main-title-semi-bold: "ClashDisplay-SemiBold", serif;

$font-main-text-bold: "Montserrat-Bold", serif; //700
$font-main-text-semi-bold: "Montserrat-SemiBold", serif; //600
$font-main-text-regular: "Montserrat-Regular", serif; //400
$font-main-text-medium: "Montserrat-Medium", serif; //500

$font-secondary-bold: "Rajdhani Bold", serif;
$font-secondary-regular: "Rajdhani Regular", serif;
$font-secondary-medium: "Rajdhani Medium", serif;

$base-fontsize: 16;
$error-text-color: #dc3545;
$primary: #0F2725;
$text-color: #707070;
$secondary-text-color: #626262;
;
$subtitle-color: #DFD0B7;
$light-subtitle-color: #F6F3D8;


$sizes: (
        '13' : 13,
        '14' : 14,
        '18' : 18,
        '60' : 60,
        '100' : 100,
        '110' : 110,
        '115' : 115,
        '120' : 120,
        '130' : 130,
        '140' : 140,
        '150' : 150,
        '160' : 160,
        '170' : 170,
        '180' : 180,
        '190' : 190,
        '200' : 200,
        '240' : 240,
        '250' : 250,
        '300' : 300,
        '340' : 340,
        '350' : 350,
) !default;

$font-families: (
        'title-regular' : $font-main-title-regular,
        'title-bold' : $font-main-title-bold,
        'text-bold' : $font-main-text-bold,
        'text-regular' : $font-main-text-regular,
        'secondary-regular' : $font-secondary-regular,
        'secondary-bold' : $font-secondary-bold,
        'secondary-medium' : $font-secondary-medium
) !default;

@each $name, $value in $sizes {
  .w-#{$name}px {
    width: #{$value}px;
  }
  .mt-#{$name} {
    margin-top: #{$value}px;
  }
  .mb-#{$name} {
    margin-bottom: #{$value}px;
  }
  .mr-#{$name} {
    margin-right: #{$value}px;
  }
  .ml-#{$name} {
    margin-left: #{$value}px;
  }

  .m-#{$name} {
    margin: #{$value}px;
  }
  .p-#{$name} {
    padding: #{$value}px;
  }
  .pl-#{$name} {
    padding-left: #{$value}px;
  }
  .pr-#{$name} {
    padding-right: #{$value}px;
  }
  .h-#{$name}px {
    height: #{$value}px;
  }
  .fs-#{$name} {
    font-size: #{$value}px !important;
  }

}

@each $name, $value in $font-families {
  .f-#{$name} {
    font-family: #{$value} !important;
  }
}