section:not(.padding-sm) {
  padding-top: 150px;
  @media (max-width: $breakpoint-991) {
    padding-top: 100px;
  }
}

.padding-sm {
  padding-top: 100px;
  @media (max-width: $breakpoint-991) {
    padding-top: 100px;
  }
}
