.toggle-list {
  list-style: none;

  &.metaverse {
    li {
      button {
        color: #AFAF89 !important;

        &.active {
          color: #EDCD72 !important;
        }
      }
    }
  }

  li {
    button {
      color: $light-subtitle-color;
      text-align: center;
      font-family: $font-main-title-semi-bold;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;
      border: unset !important;
      transition: all 0.3s;

      &.active {
        color: $primary;
        border: unset !important;
      }

      &:active {
        color: $primary;
        border: unset !important;
      }
    }
  }
}

.product-card {
  display: flex;
  flex-basis: calc(33.33% - 20px);
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product-img {
  height: 200px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
}